import React, { useState, useEffect, useRef } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Layout from "../../components/chat-channel/Layout";
import Container from "../../components/chat-channel/Container";
import RegisterSection from "../../components/chat-channel/ChannelHeader";
import { Tabs, Tab, TabPanel, TabList, TabProvider } from "react-web-tabs";
import "react-web-tabs/dist/react-web-tabs.css";
import RightImageWithContentFeature from "../../components/chat-channel/RightImageWithContent";
import LeftImageWithContent from "../../components/chat-channel/LeftImageWithContent";
import {
  ArrowRightICon,
  MobNextArrowIcon,
} from "../../components/common/Icons";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import { RequestForm } from "../../components/form";
import Cards from "../../components/chat-channel/Cards";
import useInterval from "../../react/useInterval";
import { useSwipeable, Swipeable } from "react-swipeable";
import { FeatureArrowWithColor } from "../../components/common/Icons";
import ReactBootstrapCarousel from "react-bootstrap-carousel";
import { useMedia } from "use-media";

import { VideoElement2 } from "../../components/video";
import { GoToPopup } from "../homepage";
import { CookiesPoup } from "../homepage";
import { RightArrowIconAssistants } from "../../components/common/Icons";
import {
  Onboarding_November,
  OnboardingMobile_November,
  AppsIntegrations,
  ISOSecurity_November,
  HrItSupport_November,
  HrItSupportMobile_November,
  usePreloadImages_November,
} from "../conversational-ai-platform";
import { CaseStudyCtaGoTo } from "./virtual-agent";
import { Faq } from "./knowledge-ai";

const customerBanner = require("../../assets/img/feature/home/header_customer_banner.svg");
const bannerImageMobile = require("../../assets/img/feature/home/clients_logo_mob.svg");

const capabilities_1 = require("../../assets/img/home_page_assist/it_sup_capabilities_1.png");
const capabilities_2 = require("../../assets/img/home_page_assist/it_sup_capabilities_2.png");
const capabilities_4 = require("../../assets/img/home_page_assist/it_sup_capabilities_4.png");
const capabilities_5 = require("../../assets/img/home_page_assist/itsm_capabilities_5.png");
const capabilities_6 = require("../../assets/img/home_page_assist/it_sup_capabilities_6.png");
const capabilities_7 = require("../../assets/img/home_page_assist/it_sup_capabilities_7.png");
const capabilities_8 = require("../../assets/img/home_page_assist/it_sup_capabilities_8.png");
const capabilities_9 = require("../../assets/img/home_page_assist/it_sup_capabilities_9.png");
const capabilities_10 = require("../../assets/img/home_page_assist/it_sup_capabilities_10.png");
const capabilities_11 = require("../../assets/img/home_page_assist/it_sup_capabilities_11.png");
const capabilities_12 = require("../../assets/img/home_page_assist/itsm_capabilities_12.png");
const capabilities_13 = require("../../assets/img/home_page_assist/it_sup_capabilities_13.png");

const capabilities_3_1 = require("../../assets/img/home_page_assist/itsm_sup_capabilities_3_1.png");
const capabilities_3_2 = require("../../assets/img/home_page_assist/itsm_sup_capabilities_3_2.png");
const capabilities_3_3 = require("../../assets/img/home_page_assist/itsm_sup_capabilities_3_3.png");
const capabilities_3_4 = require("../../assets/img/home_page_assist/itsm_sup_capabilities_3_4.png");
const capabilities_3_5 = require("../../assets/img/home_page_assist/itsm_sup_capabilities_3_5.png");
const capabilities_3_6 = require("../../assets/img/home_page_assist/itsm_sup_capabilities_3_6.png");
const capabilities_3_7 = require("../../assets/img/home_page_assist/itsm_sup_capabilities_3_7.png");

const ad_one = require("../../assets/img/home_page_assist/itsm_ad_1.png");
const ad_two = require("../../assets/img/home_page_assist/itsm_ad_2.png");
const ad_three = require("../../assets/img/home_page_assist/itsm_ad_3.png");

const workflow_arrow = require("../../assets/img/home_page_assist/workflow_arrow.svg");
const arrow = require("../../assets/img/home_page_assist/arrow.svg");

const onboard_one = require("../../assets/img/home_page_assist/itsm_sup_onboarding_1.png");
const onboard_two = require("../../assets/img/home_page_assist/itsm_sup_onboarding_2.png");
const onboard_three = require("../../assets/img/home_page_assist/itsm_sup_onboarding_3.png");
const onboard_four = require("../../assets/img/home_page_assist/itsm_sup_onboarding_4.png");
const onboard_five = require("../../assets/img/home_page_assist/itsm_sup_onboarding_5.png");

const arrow_round_mobile = require("../../assets/img/home_page_assist/left_arrow_mobile.svg");

const onboard_one_mob = require("../../assets/img/home_page_assist/onboarding_1_mob.png");

const FeatureSlack = require("../../assets/images/features_images/features_slack.png");
const FeatureTeams = require("../../assets/images/features_images/features_teams.png");
const FeatureChat = require("../../assets/images/features_images/features_chat.png");

const reviewer_image = require("../../assets/images/testimonials/reviewer_circle.png");
const reviewer_img_mobile = require("../../assets/images/testimonials/review_hammer.png");
const hammer_logo = require("../../assets/images/testimonials/hammer_logo.svg");
const star_image = require("../../assets/images/testimonials/star.svg");
const capterra_logo = require("../../assets/images/testimonials/capterra_logo.svg");
const g2_logo = require("../../assets/images/testimonials/g2_logo.svg");
const quotes = require("../../assets/images/testimonials/quotes.svg");
const goto_manager = require("../../assets/images/testimonials/goto_manager_2.png");
const goto_manager_mobile = require("../../assets/images/testimonials/goto_manager_1.png");
const goto_logo = require("../../assets/images/testimonials/goto_logo.svg");
const hammer_manager_mobile = require("../../assets/images/testimonials/reviewer_circle.png");

const topImage = require("../../assets/img/home_page_assist/it_bot_header.png");
const VidImg = require("../../assets/img/chatbot/it_chatbot/vid_img_itsm.png");
const VidImgMob = require("../../assets/img/chatbot/it_chatbot/vid_img_itsm_mobile.png");
const slides = [
  {
    image: capabilities_3_1,
    title: "Unlock Account",
    tabFor: "vertical-tab-one",
    text: "unlock account",
  },
  {
    image: capabilities_3_2,
    title: "Password Reset",
    tabFor: "vertical-tab-two",
    text: "password resets",
  },
  {
    image: capabilities_3_3,
    title: "User Provisioning",
    tabFor: "vertical-tab-three",
    text: "user provisioning",
  },
  {
    image: capabilities_3_4,
    title: "Access Management",
    tabFor: "vertical-tab-four",
    text: "access management",
  },
  {
    image: capabilities_3_5,
    title: "Asset Requests",
    tabFor: "vertical-tab-five",
    text: "asset requests",
  },
  {
    image: capabilities_3_6,
    title: "Onboarding",
    tabFor: "vertical-tab-six",
    text: "employee onboarding",
  },
  {
    image: capabilities_3_7,
    title: "Offboarding",
    tabFor: "vertical-tab-seven",
    text: "employee offboarding",
  },
];
export const faqData = [
  {
    header: "How does an IT helpdesk chatbot work?",
    paragraph:
      "An IT helpdesk chatbot powered by Gen AI uses large language models (LLM) to understand the intent and context of your query. It processes the input and generates contextually accurate responses using Generative AI. The chatbot leverages an extensive knowledge base and follows a pre-configured conversation flow, dynamically adapting to each interaction to provide faster, more efficient solutions for your support needs.",
    active: "active",
    uuid: "a",
  },
  {
    header: "What are the benefits of implementing an IT helpdesk chatbot?",
    paragraph:
      "An IT helpdesk chatbot has the following benefits for your support team:",
    listItems: [
      "Automates routine and trivial tasks",
      "Reduces Mean Time to Resolution (MTTR)",
      "Helps scale your IT operations",
      "Reduces email and call volume",
      "Enables the support team to focus on critical queries",
      "Easy to implement without the need for IT experts",
    ],
    listType: "list-style-disc",
  },
  {
    header:
      "Can an IT helpdesk chatbot provide real-time assistance to users?",
    paragraph:
      "Yes, IT helpdesk chatbots can provide assistance 24/7. You can configure them to provide automated responses where most use cases are handled through appropriate workflows. Moreover, if the chatbot can’t resolve a query, agent handover to a live agent can be done in real-time. This enables quicker response time.",
  },
  {
    header:
      "How do Large Language Models (LLM) contribute to the effectiveness of an IT helpdesk chatbot?",
    paragraph:
      "An IT helpdesk chatbot powered by large language models (LLM) continuously learns and evolves to provide real-time, accurate responses to user queries. Using LLMs, the chatbot understands and processes complex language patterns, recognizing synonyms, grammar variations, and slang, ensuring it can respond appropriately to diverse inputs. Additionally, Workativ’s Knowledge AI enables seamless context switching, allowing the chatbot to effortlessly adapt when a user changes the topic of their query, providing a fluid and effective self-service experience.",
  },
  {
    header:
      "What security measures are in place to protect users' data when using an IT helpdesk chatbot?",
    paragraph: "Some of the security measures to protect user data:",
    listItems: [
      "Personally Identifiable Information(PII) is masked to prevent leakage of personal data.",
      "Real-time human supervision.",
      "Vulnerabilities like prompt injection attacks can be restricted using model fine-tuning or preprocessing data before they are fed into the model.",
      "Users of chatbots should never be asked to share sensitive data like passwords.",
      "Identity and access control can be performed using security keys or OTPs.",
    ],
    listType: "list-style-disc",
    paragraph1: `You can check the security policy <a href="https://workativ.com/security">here.</a>`,
  },
  {
    header:
      "How can an IT helpdesk chatbot be integrated with existing IT systems and tools?",
    paragraph: `We have an <a href="https://workativ.com/conversational-ai-platform/app-workflow-template-marketplace">app workflow marketplace</a> with integrations for different tools. You can use our pre-built app integrations and app workflows to enable easy configuration across a variety of IT apps without the need for coding.`,
  },
  {
    header: "What issues or inquiries can an IT helpdesk chatbot handle?",
    paragraph:
      "The IT helpdesk chatbot can handle ticket automation, troubleshooting, standard requests like resetting a password or unlocking an account, user provisioning, asset and access management, and other IT queries. It can also gather information from the knowledge base for the employee, along with references.",
  },
  {
    header:
      "How does an IT helpdesk chatbot facilitate ticketing and issue resolution processes?",

    paragraph:
      "When an employee asks a query, the IT helpdesk chatbot automatically resolves it using advanced automation technology. By leveraging pre-defined workflows and a comprehensive knowledge base, the chatbot delivers immediate solutions for common issues. If it cannot resolve the query, it creates a ticket or escalates the issue to a live agent for further assistance, ensuring that employees receive prompt support and minimizing the downtime.",
  },
  {
    header:
      "What are the best practices for training an IT helpdesk chatbot to ensure accurate and relevant responses?",

    paragraph: "Some of the best practices for training IT helpdesk chatbot:",
    listItems: [
      "Analyze and identify all processes that need automation",
      "Use the latest knowledge base articles and accurate data to help ensure precise responses",
      "Maintain existing knowledge base and keep them updated regularly",
      "Update data based on employee feedback",
      "Ensure transparency and fairness against potential biases in AI algorithms",
      "Adhere to regulatory compliance and ethical guidelines",
      "Human supervision is necessary",
    ],
    listType: "list-style-disc",
  },
  {
    header:
      "How can an organization measure the performance and success of an IT helpdesk chatbot implementation?",
    paragraph:
      "You can measure the performance and success of an IT helpdesk chatbot implementation by analyzing several key metrics that provide insights into usage, effectiveness, and impact:",
    headerWithMultiPara: true,

    subHeaderData: [
      {
        subHeader: "Bot Sessions",
        para:
          "Track how employees use the chatbot by examining session data, including the number of sessions, peak usage times, and average session duration. Reviewing the most helpful conversations gives a deeper understanding of which topics or interactions are most valuable to users.",
      },
      {
        subHeader: "User Queries",
        para:
          "Monitor how the chatbot handles user queries to evaluate resolution, escalation, and abandonment rates over time. This helps determine the chatbot's effectiveness in addressing questions and highlights areas where users might need additional support.",
      },
      {
        subHeader: "Agent Handover",
        para:
          "Analyze insights related to query escalations to live agents. Understanding when and why handovers occur helps optimize the bot to handle more queries independently, minimizing the need for human intervention.",
      },
      {
        subHeader: "Live Bot Status",
        para:
          "Observe real-time usage data to monitor live interactions across different chat channels. This feature provides immediate insights into active sessions and user engagement, ensuring the chatbot remains responsive during peak times.",
      },
      {
        subHeader: "User Metrics",
        para:
          "Dive into user metrics over time to compare frequent users with sporadic users and assess other vital engagement indicators. These metrics help identify the chatbot's adoption level within the organization.",
      },
      {
        subHeader: "ROI",
        para:
          "Use ROI analytics to present the chatbot's value to management by demonstrating cost savings, productivity improvements, and efficiency gains. This data is essential for proving the financial impact and justifying the continued investment in chatbot technology.",
      },
      {
        subHeader: "Unanswered Queries",
        para:
          "Review any queries that the chatbot couldn't answer. Analyzing these gaps allows the organization to expand the chatbot's knowledge base by adding new dialogs, enhancing its ability to cover more topics, and reducing unanswered questions.",
      },
      {
        subHeader: "Key Efficiency Metrics (FCR, MTTR, and Hours Saved)",
        para:
          "Track First Contact Resolution (FCR) rate, Mean Time to Resolution (MTTR), and saved hours for agents and users. High FCR rates and reduced MTTR reflect effective query handling, while the hours saved quantify the productivity boost for both users and agents. Additionally, cost savings can be calculated based on reduced manual interventions and quicker resolutions.",
      },
    ],
  },
  {
    header: "Where can I deploy the IT helpdesk chatbot?",

    paragraph:
      "You can deploy the IT helpdesk chatbot on platforms like Microsoft Teams, Slack, and your website for seamless employee support.",
  },
  {
    header: "Does the IT helpdesk chatbot have a live agent handover feature?",

    paragraph:
      "Yes, the chatbot includes a live agent handover feature, enabling it to transfer complex or unresolved queries to a human agent when needed. ",
  },
  {
    header:
      "What is a multi-channel shared live inbox for the IT helpdesk chatbot, and how does it benefit my team?",

    paragraph:
      "A multi-channel shared live inbox for the IT helpdesk chatbot is an AI-enhanced platform that consolidates communications from various channels into a single, organized interface. It allows your support team to manage and respond to inquiries more efficiently, as they can quickly access all incoming messages from different sources in one place.",
  },
];

export default function FeatureHomePage() {
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <>
      <TitleAndMetaTags
        title="Free IT Helpdesk Chatbot | IT Helpdesk Automation | IT Helpdesk AI"
        description="Use IT Helpdesk chatbot to automate repetitive IT issues and requests Augment your IT agents with intelligent chatbot automation for workplace support"
        keywords={[
          "HR chatbot, Automation, AI Chatbot",
          "workativ free trial",
          "helpdesk chatbot free trial",
          "automation",
        ]}
        ogImage={topImage}
      />
      <section className="it_support_chatbot container_trial integrations_container landing_page_chatbot support_chatbot_it_help">
        <Container>
          <Layout backgroundColor={"landing_bg"} logoFor="ASSISTANT">
            <RegisterSection
              rightImage={topImage}
              backgroundColor={"landing_bg"}
              altImage={"IT helpdesk automation"}
              additionalClass={"padding-top-15 it_support_new_page"}
              iD={"chatbot"}
              additionalClassImage={"w-90"}
            >
              <RegisterSection.FormWithContent>
                <RegisterSection.MainHeader>
                  80% of repetitive IT support issues can be automated
                </RegisterSection.MainHeader>
                <RegisterSection.SubHeader>
                  Workativ helps you automate your employee IT issues and
                  service requests with Gen AI based IT Helpdesk Chatbot
                  combined with IT workflow automation.
                </RegisterSection.SubHeader>
              </RegisterSection.FormWithContent>
            </RegisterSection>
            <div
              className={`float-left w-100 ${isSmall ? "mb-4" : "mb-5 mt-5"}`}
            >
              <div className="container">
                <h5
                  className={`text-center font-section-normal-text-medium-customer-banner it-help-title ${
                    isSmall ? "mb-3" : "margin-bottom-20 "
                  }`}
                >
                  Join hundreds of industry leaders
                </h5>{" "}
                {isSmall ? (
                  <img className="" src={bannerImageMobile} />
                ) : (
                  <img className="  d-flex m-auto" src={customerBanner} />
                )}
              </div>
            </div>
            {isSmall ? (
              <HrItSupportMobile_November
                tabContent={tabsForHrIt_November}
                imagesToPreload={tabsForHrItImages}
                header={"IT Helpdesk Chatbot Capabilities"}
              />
            ) : (
              <HrItSupport_November
                tabContent={tabsForHrIt_November}
                imagesToPreload={tabsForHrItImages}
                header={"IT Helpdesk Chatbot Capabilities"}
                headerGroupByCountList={[4, 5, 4]}
                isComingSoon={tabsForHrIt_November.isComingSoon}
              />
            )}
            <CaseStudyCtaGoTo colorKey="peach" textColor="blue" />
            <AdHomePage_November />
            {isSmall ? (
              <>
                <OnboardingMobile_November
                  tabContent={tabsForOnBoarding_November}
                  imagesToPreload={tabsForOnBoardingImages_November}
                />
                <TestimonialsSupportMobile_November />
              </>
            ) : (
              <OnboardingTestimonialsSupport_November
                imagesToPreload={tabsForOnBoardingImages_November}
              />
            )}
            <RequestForm
              isFooterForm={true}
              additionalClass={isSmall ? "mt-0" : "mt-5"}
            />
            <div class="container">
              <div className="build_needs">
                <div
                  class={`col-lg-12 col-md-12 col-12 pl-0 center_features_header mt-2 ${
                    isSmall ? "mb-0" : "mb-3"
                  }`}
                >
                  <h2 className="font-section-sub-header pb-0">
                    Steps to build IT Helpdesk Chatbot with app automation
                  </h2>
                </div>
              </div>
            </div>
            <VideoElement2
              videoURL={"https://www.youtube-nocookie.com/embed/DvZpkC54JYg"}
              imgURL={VidImg}
              imgMob={VidImgMob}
            />

            <AppsIntegrations
              header={
                isSmall ? (
                  <h3 className="font-section-sub-header mb-3">
                    Connect IT Helpdesk Chatbot with 100+ apps, 600+ actions,
                    and 1000s of automations instantly. No coding
                  </h3>
                ) : (
                  <>
                    <h3 className="font-section-sub-header mb-2">
                      Connect IT Helpdesk Chatbot with 100+ apps, 600+ actions,
                      and 1000s of automations instantly. No coding
                    </h3>
                  </>
                )
              }
              additionalClass={isSmall ? "mb-4" : "mb-5"}
              footer={
                <>
                  Also available CRM, Expense Management, Document Management,
                  Project Management, Email Management, SMS Management,
                  Notifications, and Collaboration Apps.
                </>
              }
            />
            <ISOSecurity_November />
            <Faq faqData={faqData} marginTrue={true} />
            <div className="container">
              <div className="usecase_blog_container">
                <h3 class="font-section-sub-header-small-bold-v2">
                  Must Read Blogs
                </h3>
                <div className="usecase_bottom_blog">
                  <ul>
                    <li>
                      <a
                        className="blog_usecase_link font-section-normal-text"
                        href="https://workativ.com/conversational-ai-platform/virtual-agent-powered-it-helpdesk"
                      >
                        How Virtual Agent can help IT Service Desks? Upgrade to
                        a Virtual Agent powered IT Helpdesk
                      </a>
                    </li>
                    <li>
                      <a
                        className="blog_usecase_link font-section-normal-text"
                        href="https://workativ.com/conversational-ai-platform/slack-chatbot-guide"
                      >
                        Slack Chatbot Guide for IT Helpdesk Automation
                      </a>
                    </li>
                    <li>
                      <a
                        className="blog_usecase_link font-section-normal-text"
                        href="https://workativ.com/conversational-ai-platform/10-major-it-workflow-automation-benefits"
                      >
                        10 Best IT Workflow Automation Practices to follow for
                        Chatbots designed with Workativ Assistant
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* <GoToPopup />
            <CookiesPoup /> */}
            <RequestForm isFooterForm={true} />
          </Layout>
        </Container>
      </section>
    </>
  );
}

export function AdHomePage_November() {
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <section className="w-100 float-left ad_home_page_main_container">
      <div className="container">
        <h4 className="font-section-sub-header text-align-center mb-4">
          See how companies benefit from AI-powered chatbot with auto-resolution
        </h4>
        <div className="ad_flex_container_assist">
          <div className="ad_container_one_assist">
            <h5 className="font-section-normal-text-medium text-align-center mb-5">
              Reduce your Mean Time To Resolution (to seconds)
            </h5>
            {isSmall ? <img src={ad_one}></img> : <img src={ad_one}></img>}
          </div>
          <div className="ad_container_two_assist">
            <h5 className="font-section-normal-text-medium text-align-center mb-5">
              Reduce emails and call volumes with instant auto-resolution
            </h5>
            <img src={ad_two}></img>
          </div>
          <div className="ad_container_three_assist">
            <h5 className="font-section-normal-text-medium text-align-center mb-5">
              Scale your support instantly with ease
            </h5>
            <img src={ad_three}></img>
          </div>
        </div>
        {isSmall ? (
          <span className="ad_mobile_right_arrow">
            <RightArrowIconAssistants />
          </span>
        ) : null}
      </div>
    </section>
  );
}

function OnboardingTestimonialsSupport_November() {
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <section className="onboarding_testimoanials_main_container w-100 float-left">
      <Onboarding_November tabContent={tabsForOnBoarding_November} />
      <div className="container">
        <div className="row">
          <div className="main_container_testimonials_usecase testimonials_container_support">
            <div className="top_image_container_testimonials_usecase top_image_container_testimonials_it_support">
              <img
                loading="lazy"
                className="capterra_logo_left capterra_usecase"
                src={capterra_logo}
              />
              <img
                loading="lazy"
                src={star_image}
                className="rating_star_image"
              ></img>
              <img
                loading="lazy"
                src={star_image}
                className="rating_star_image"
              ></img>
              <img
                loading="lazy"
                src={star_image}
                className="rating_star_image"
              ></img>
              <img
                loading="lazy"
                src={star_image}
                className="rating_star_image"
              ></img>
              <img
                loading="lazy"
                src={star_image}
                className="rating_star_image"
              ></img>
              <span>5/5</span>
            </div>
            <div className="middle_content_container_testimonials_usecase">
              <h5 className="font-section-sub-header">
                “Workativ delivers and the implementation team is top notch.
                Very supportive and responsive to question”
              </h5>
              <p className="font-section-normal-text-testimonials">
                <span className="font-section-normal-text-medium color-black">
                  Overall:&nbsp;
                </span>
                Very positive experience. We are able to grow the bot based on
                our internal needs. The cost and support can’t be beat for all
                the features the tool provides
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <span className="font-section-normal-text-medium color-black">
                  Pros:&nbsp;
                </span>
                It just seems to work out of the box. If you have a decent
                outline of the needs the bot needs to fill it is really
                straightforward to set up and test. Their deployment team offers
                insight on where to focus time and provides resources to go to
                quickly resolve questions on your own. Truly self service.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                <span className="font-section-normal-text-medium color-black">
                  Cons:&nbsp;
                </span>
                No complaints right now - when we have had issues they respond
                quickly with a plan and their follow through is excellent
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0">
                <span className="font-section-normal-text-medium color-black">
                  Reasons for Choosing Workativ Assistant:&nbsp;
                </span>
                Cost and the willingness of Workativ to answer questions and
                ensure our internal business requirements were being met.
              </p>
            </div>
            <div className="bottom_button_content_container_testimonials">
              <div className="bottom_reviewer_image_container_testimonials_usecase">
                <img
                  loading="lazy"
                  src={reviewer_image}
                  className="testimonial_reviewer_image_landing_page"
                  alt="hammer_manager"
                ></img>
                <div className="end_name_usecase">
                  <h5
                    className="font-section-sub-header-bold-goto-case hammer_dir_name mb-0"
                    style={{ color: "#4F75D9" }}
                  >
                    Dan Bottiger
                  </h5>
                  <h5 className="font-section-normal-text-medium reviewer_designation_hammer mb-0">
                    Director of Information Systems
                  </h5>

                  <img
                    src={hammer_logo}
                    className="it_support_hammer_logo h-auto"
                  ></img>
                </div>
              </div>
              <button
                className="goto_case_study_button_redirection button_usecase_it_support w-30 mt-3 h-fit-content"
                onClick={() => {
                  window.location.href =
                    "/conversational-ai-platform/case-studies/goto-implements-chatbot-for-it-helpdesk-automation";
                }}
              >
                <span className="font-section-normal-text-medium">
                  Explore success stories
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
function TestimonialsSupportMobile_November() {
  return (
    <section class="testimonial_background_main_container_mobile it_support_mobile_testimonials float-left">
      <h3 className="font-section-sub-header text-align-center mb-3">
        Customer testimonial
      </h3>
      <div className="testimonial_feedback_box_mobile home_assistant_tesimonials_content_mobile">
        <div className="testimonial_feedback_top_content_box_mobile">
          <p className="font-section-normal-text-testimonials text-align-left line-height-18">
            <span>
              <img
                src={quotes}
                className="quotes_image_testimonial h-auto"
              ></img>
            </span>
            The setup and building of the Workativ Chatbot I felt was extremely
            well supported! My questions were answered quickly and at no point
            did I feel unsupported. I had multiple Zoom meetings through the
            process where we would meet to go through the build.
          </p>
          <p className="font-section-normal-text-testimonials text-align-left line-height-18">
            When the build hit a hurdle, the resolution was found and
            implemented within 24-48 hours. Through this communication was
            excellent.
          </p>
          <p className="font-section-normal-text-testimonials text-align-left line-height-18 mb-2">
            I felt listened through what we absolutely had to have for this tool
            to be rolled out in our environment. Throughout the process we kept
            looking back at the business requirements to make sure we were
            aligning properly.
          </p>
        </div>

        <img
          src={hammer_manager_mobile}
          className="testimonial_reviewer_image h-auto"
          alt="hammer_global_manager"
          style={{ width: "80px" }}
        ></img>

        <div className="bottom_designation_box_right_mobile ">
          <h5
            className="font-section-sub-header-smaller-bold-testimonials mt-3"
            style={{ color: "#4F75D9" }}
          >
            Dan Bottiger
          </h5>
          <h5 className="font-section-normal-text-medium reviewer_designation_hammer">
            Director of Information Systems
          </h5>
          <img src={hammer_logo} className="w-35 h-auto"></img>
        </div>
        <button
          className="goto_case_study_button_redirection w-100 mt-3"
          onClick={() => {
            window.location.href =
              "/conversational-ai-platform/case-studies/goto-implements-chatbot-for-it-helpdesk-automation";
          }}
        >
          <span className="font-section-normal-text-medium">
            Explore success stories
          </span>
        </button>
      </div>
    </section>
  );
}

const tabsForOnBoarding_November = [
  {
    header: "Sign up",
    hasGridLayoutBigger: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="onboard_content_left_container">
          <h3 className="font-section-normal-text-medium-link">Sign up</h3>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            You can create a new bot or download pre-built IT Helpdesk Bot from
            marketplace.
          </p>
        </div>
        <div className="onboard_content_right_container">
          <img src={onboard_one} alt="Sign up"></img>
        </div>{" "}
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-sub-header">Sign up</h3>
        <p className="font-section-normal-text-testimonials line-height-18 mb-0">
          You can create a new bot or download pre-built IT Helpdesk Bot from
          marketplace.
        </p>
        <img src={onboard_one_mob} alt="Sign up"></img>
      </>
    ),
  },
  {
    header: "Download IT Bot",
    hasGridLayoutBigger: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="onboard_content_left_container">
          <h3 className="font-section-normal-text-medium-link">
            Download IT Helpdesk Bot
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18">
            Bot templates are pre-built bots with conversations covering most
            common and repetitive support topics.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            Easily download IT Helpdesk bot in one-click, add new or edit
            existing support topics in minutes.
          </p>
        </div>
        <div className="onboard_content_right_container">
          <img src={onboard_two} alt="Bot Marketplace"></img>
        </div>{" "}
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-sub-header">Download IT Helpdesk Bot</h3>
        <p className="font-section-normal-text-testimonials line-height-18">
          Bot templates are pre-built bots with conversations covering most
          common and repetitive support topics.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18">
          Easily download IT Helpdesk bot in one-click, add new or edit existing
          support topics in minutes.
        </p>
        <img src={onboard_two} alt="Bot Marketplace"></img>
      </>
    ),
  },
  {
    header: "Connect App Workflows",
    hasGridLayoutBigger: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="onboard_content_left_container">
          <h3 className="font-section-normal-text-medium-link">
            Connect App Workflows
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18">
            Connecting chatbot with your apps and building app workflows
            requires a lot of development efforts, time, and costs.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            But workativ provides pre-built app integrations and app workflows
            so you can connect your chatbot with your app and automate tasks
            instantly. No coding.
          </p>
        </div>
        <div className="onboard_content_right_container">
          <img src={onboard_three} alt="Connect App Workflows"></img>
        </div>{" "}
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-sub-header">Connect App Workflows</h3>
        <p className="font-section-normal-text-testimonials line-height-18">
          Connecting chatbot with your apps and building app workflows requires
          a lot of development efforts, time, and costs.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18">
          But workativ provides pre-built app integrations and app workflows so
          you can connect your chatbot with your app and automate tasks
          instantly. No coding.
        </p>

        <img src={onboard_three} alt="Connect App Workflows"></img>
      </>
    ),
  },
  {
    header: "Test your Bot",
    hasGridLayoutBigger: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="onboard_content_left_container">
          <h3 className="font-section-normal-text-medium-link">Bot Testing</h3>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            Test your bot in real time using “Try Me”. Make adjustments, add or
            edit conversations, fine tune user interactions, and review your bot
            conversations on the go before you go-live.
          </p>
        </div>
        <div className="onboard_content_right_container">
          <img src={onboard_four} alt="Test your Bot"></img>
        </div>{" "}
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-sub-header">Bot Testing</h3>
        <p className="font-section-normal-text-testimonials line-height-18">
          Test your bot in real time using “Try Me”. Make adjustments, add or
          edit conversations, fine tune user interactions, and review your bot
          conversations on the go before you go-live.
        </p>
        <img src={onboard_four} alt="Test your Bot"></img>
      </>
    ),
  },
  {
    header: "Go Live",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="onboard_content_left_container pr-0">
          <h3 className="font-section-normal-text-medium-link">
            Deploy on Teams, Slack, or as Chat Widget
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18">
            Turn your favorite collaboration app into modern employee
            self-service by deploying chatbot on your Teams, Slack or as Widget
            on your self-help portal in few clicks.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18">
            Customize the end-user experience by applying your company branding,
            logo etc.
          </p>
          <div className="icons_container_onboarding">
            <div className="icon_text">
              <img
                loading="lazy"
                class="slack_feature_icon_onboarding"
                src={FeatureSlack}
              ></img>
              <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
                Slack
              </h6>
            </div>

            <div className="icon_text">
              <img
                loading="lazy"
                class="teams_feature_icon_onboarding"
                src={FeatureTeams}
              ></img>
              <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
                Teams
              </h6>
            </div>
            <div className="icon_text">
              <img
                loading="lazy"
                class="chat_feature_icon_onboarding"
                src={FeatureChat}
              ></img>
              <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
                Chat Widget
              </h6>
            </div>
          </div>
        </div>
        <div className="onboard_content_right_container">
          <img src={onboard_five} alt="Go Live"></img>
        </div>{" "}
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-sub-header">
          Deploy on Teams, Slack, or as Chat Widget
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18">
          Turn your favorite collaboration app into modern employee self-service
          by deploying chatbot on your Teams, Slack or as Widget on your
          self-help portal in few clicks.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18">
          Customize the end-user experience by applying your company branding,
          logo etc.
        </p>
        <div className="icons_container_onboarding mb-3">
          <div className="icon_text">
            <img
              loading="lazy"
              class="slack_feature_icon_onboarding"
              src={FeatureSlack}
            ></img>
            <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
              Slack
            </h6>
          </div>

          <div className="icon_text">
            <img
              loading="lazy"
              class="teams_feature_icon_onboarding"
              src={FeatureTeams}
            ></img>
            <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
              Teams
            </h6>
          </div>
          <div className="icon_text">
            <img
              loading="lazy"
              class="chat_feature_icon_onboarding"
              src={FeatureChat}
            ></img>
            <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
              Chat Widget
            </h6>
          </div>
        </div>
        <img src={onboard_five} alt="Go Live"></img>
      </>
    ),
  },
];

const tabsForOnBoardingImages_November = [
  onboard_one,
  onboard_two,
  onboard_three,
  onboard_four,
  onboard_five,
  onboard_one_mob,
];

const tabsForHrIt_November = [
  {
    header: "IT Helpdesk Chatbot",
    hasZeroPaddedDesktopImage: true,
    hasZeroPaddedDesktopImageRight: true,
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        <div className="support_content_left_container it_support_left_content_container padding-bottom-40">
          <h3 className="font-section-normal-text-medium-link">
            Cover a wide range of topics for your IT Helpdesk chatbot
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18">
            Using our no-code bot platform, you can easily add, remove, or
            optimze support topics for chatbot based on your requirement in
            minutes.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0 color-light-black">
            Go digital with IT Helpdesk Chatbot to deliver employee support -
            faster, smarter, and easier.
          </p>
        </div>
        <div className="support_content_right_container align-self-end pb-0">
          <img src={capabilities_1} alt="IT Helpdesk Chatbot"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <React.Fragment>
        {" "}
        <h3 className="font-section-normal-text-medium-customer-banner">
          Cover a wide range of topics for your IT Helpdesk chatbot
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-1">
          Using our no-code bot platform, you can easily add, remove, or optimze
          support topics for chatbot based on your requirement in minutes.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0 color-light-black">
          Go digital with IT Helpdesk Chatbot to deliver employee support -
          faster, smarter, and easier.
        </p>
        <img src={capabilities_1} alt="IT Helpdesk Chatbot"></img>
        <hr className="underline_hr_it_support"></hr>
      </React.Fragment>
    ),
  },
  {
    header: "Knowledge AI",
    hasGridLayoutLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">Knowledge AI</h3>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            Leverage the power of Large Language Model (LLM) and Generative AI
            to super charge your knowledge base and respond to user queries in
            seconds.
          </p>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={capabilities_2} alt="knowledge ai"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <React.Fragment>
        {" "}
        <h3 className="font-section-normal-text-medium-link">Knowledge AI</h3>
        <p className="font-section-normal-text-testimonials  line-height-18 mb-0 pb-0">
          Leverage the power of Large Language Model (LLM) and Generative AI to
          super charge your knowledge base and respond to user queries in
          seconds.
        </p>
        <img
          src={capabilities_2}
          alt="knowledge ai"
          className="mobile-image-with-box-shadow"
        ></img>
      </React.Fragment>
    ),
  },
  {
    header: "App Workflow Automation",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <SectionWithImagesWithExternalClick slides={slides} />
      </React.Fragment>
    ),
    mobileContent: () => (
      <React.Fragment>
        {" "}
        <SectionWithImagesWithExternalClickMobile slides={slides} />{" "}
      </React.Fragment>
    ),
  },
  {
    header: "Shared Live Inbox",
    hasGridLayoutLargeSmall: true,
    isComingSoon: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container d-flex flex-column justify-content-center h-100 position-relative">
          <h3 className="font-section-normal-text-medium-link">
            Shared Inbox with live chat{" "}
            <sup style={{ top: "0em ", color: "#E05959", fontSize: "24px" }}>
              *
            </sup>
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            Engage users with confidence using shared inbox for live chat, bot
            transfers, actions, and much more.
          </p>
          <span className="position-absolute bottom-40 font-section-small-signup-form-medium">
            <sup style={{ top: "0em ", color: "#E05959", fontSize: "15px" }}>
              *
            </sup>
            Coming soon
          </span>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={capabilities_4} alt="shared inbox"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <React.Fragment>
        <h3 className="font-section-normal-text-medium-link">
          Shared Inbox with live chat{" "}
          <sup style={{ top: "0em ", color: "#E05959", fontSize: "16px" }}>
            *
          </sup>
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          Engage users with confidence using shared inbox for live chat, bot
          transfers, actions, and much more.
        </p>
        <span className="font-section-small-signup-form-medium">
          <sup style={{ top: "0em ", color: "#E05959", fontSize: "15px" }}>
            *
          </sup>
          Coming soon
        </span>

        <img
          src={capabilities_4}
          alt="shared inbox"
          className="mobile-image-with-box-shadow"
        ></img>
      </React.Fragment>
    ),
  },

  {
    header: "Bot Marketplace",
    hasGridLayoutLargeSmall: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container pr-0">
          <h3 className="font-section-normal-text-medium-link">
            Add pre-built tasks to Freshdesk Chatbot from our marketplace
          </h3>

          <p className="font-section-normal-text-testimonials line-height-18">
            Workativ comes with over 1000+ pre-built app workflow automations
            for popular apps like ITSM, HR, Access Management, MDM, Knowledge
            Management and so on, that are ready to use from our workflow
            marketplace.  No coding.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0 color-light-black">
            Just download, connect, and go live instantly.
          </p>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={capabilities_5} alt="Bot Marketplace"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <React.Fragment>
        {" "}
        <h3 className="font-section-normal-text-medium-customer-banner">
          Add pre-built tasks to Freshdesk Chatbot from our marketplace
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-1">
          Workativ comes with over 1000+ pre-built app workflow automations for
          popular apps like ITSM, HR, Access Management, MDM, Knowledge
          Management and so on, that are ready to use from our workflow
          marketplace.  No coding.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0 color-light-black">
          Just download, connect, and go live instantly.
        </p>
        <img src={capabilities_5} alt="Bot Marketplace"></img>
      </React.Fragment>
    ),
  },
  {
    header: "Omnichannel",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">
            Deploy IT Helpdesk Chatbot on your Slack, Teams or as a Widget in
            few clicks
          </h3>

          <p className="font-section-normal-text-testimonials line-height-18 ">
            Companies using IT Bot have seen to automate 60% of issues &
            requests, and scale support much faster.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            {" "}
            Delight your employees with always-on 24/7, follow-me,
            auto-resolution IT chatbot, from the comfort Slack, Teams, or a Chat
            Widget.
          </p>
          <div className="icons_container_onboarding mt-0">
            <div className="icon_text">
              <img
                loading="lazy"
                class="slack_feature_icon_onboarding"
                src={FeatureSlack}
              ></img>
              <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
                Slack
              </h6>
            </div>

            <div className="icon_text">
              <img
                loading="lazy"
                class="teams_feature_icon_onboarding"
                src={FeatureTeams}
              ></img>
              <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
                Teams
              </h6>
            </div>
            <div className="icon_text">
              <img
                loading="lazy"
                class="chat_feature_icon_onboarding"
                src={FeatureChat}
              ></img>
              <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
                Chat Widget
              </h6>
            </div>
          </div>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={capabilities_6} alt="Omnichannel"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <React.Fragment>
        {" "}
        <h3 className="font-section-normal-text-medium-customer-banner">
          Deploy IT Helpdesk Chatbot on your Slack, Teams or as a Widget in few
          clicks
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-1">
          Companies using IT Bot have seen to automate 60% of issues & requests,
          and scale support much faster.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          {" "}
          Delight your employees with always-on 24/7, follow-me, auto-resolution
          IT chatbot, from the comfort Slack, Teams, or a Chat Widget.
        </p>
        <div className="icons_container_onboarding mt-2 mb-4">
          <div className="icon_text">
            <img
              loading="lazy"
              class="slack_feature_icon_onboarding"
              src={FeatureSlack}
            ></img>
            <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
              Slack
            </h6>
          </div>

          <div className="icon_text">
            <img
              loading="lazy"
              class="teams_feature_icon_onboarding"
              src={FeatureTeams}
            ></img>
            <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
              Teams
            </h6>
          </div>
          <div className="icon_text">
            <img
              loading="lazy"
              class="chat_feature_icon_onboarding"
              src={FeatureChat}
            ></img>
            <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
              Chat Widget
            </h6>
          </div>
        </div>
        <img src={capabilities_6} alt="Omnichannel"></img>
      </React.Fragment>
    ),
  },
  {
    header: "Agent Handover",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">
            Live Agent Handover
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18">
            Workativ offers seamless{" "}
            <b className="color-black-resource font-section-normal-text-testimonials-medium">
              ‘agent handover’
            </b>{" "}
            of chatbot to live agents with complete context and user
            conversation history so your agents can pick up right where the bot
            left, avoiding any user frustration – but most importantly solving
            end-user issues right away.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Make your MS Teams a live agent channel instantly.
          </p>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={capabilities_7} alt="Agent Handover"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <React.Fragment>
        {" "}
        <h3 className="font-section-normal-text-medium-customer-banner">
          Live Agent Handover
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-1">
          Workativ offers seamless{" "}
          <b className="color-black-resource font-section-normal-text-testimonials-medium">
            ‘agent handover’
          </b>{" "}
          of chatbot to live agents with complete context and user conversation
          history so your agents can pick up right where the bot left, avoiding
          any user frustration – but most importantly solving end-user issues
          right away.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          Make your MS Teams a live agent channel instantly.
        </p>
        <img src={capabilities_7} alt="Agent Handover"></img>
      </React.Fragment>
    ),
  },
  {
    header: "Approvals",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">
            Approve your chatbot requests in seconds
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18 ">
            Automating workplace requests can sometimes require manager or
            special approval. Workativ helps you automate and manage approvals
            for employee requests from chatbot easily out-of-the box with
            approval management capability.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Setting up approvals, notification, reminders, expiry, and tracking
            made simple for you.
          </p>
        </div>
        <div className="support_content_right_container">
          <img src={capabilities_8} alt="Approvals"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <React.Fragment>
        {" "}
        <h3 className="font-section-normal-text-medium-customer-banner">
          Approve your chatbot requests in seconds
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-1">
          Automating workplace requests can sometimes require manager or special
          approval. Workativ helps you automate and manage approvals for
          employee requests from chatbot easily out-of-the box with approval
          management capability.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          Setting up approvals, notification, reminders, expiry, and tracking
          made simple for you.
        </p>
        <img src={capabilities_8} alt="Approvals"></img>
      </React.Fragment>
    ),
  },
  {
    header: "Context Switching",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">
            Context Switching
          </h3>

          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            User conversations are never singular. A typical conversation may
            involve more than one context and goal, and even more confusing when
            the user decides to change the request halfway. Workativ handles
            context switching swiftly without loosing users.
          </p>
        </div>
        <div className="support_content_right_container">
          <img src={capabilities_9} alt="context switching"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-normal-text-medium-link">
          Context Switching
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          User conversations are never singular. A typical conversation may
          involve more than one context and goal, and even more confusing when
          the user decides to change the request halfway. Workativ handles
          context switching swiftly without loosing users.
        </p>
        <img src={capabilities_9} alt="context switching"></img>
      </>
    ),
  },
  {
    header: "Sensitive Data Handling",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">
            Sensitive Data Handling
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            It gives the ability for the admin to securely handle the user
            information provided to the bot and resolve the user query without
            storing or displaying the user information.{" "}
            <span className="font-section-normal-text-testimonials-medium color-black">
              Example:
            </span>{" "}
            User wants to reset their password and provides the new password to
            the chatbot, and the bot will update the password and will not store
            or display.
          </p>
        </div>
        <div className="support_content_right_container">
          <img src={capabilities_10} alt="sensitive data handling"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-normal-text-medium-link">
          Sensitive Data Handling
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          It gives the ability for the admin to securely handle the user
          information provided to the bot and resolve the user query without
          storing or displaying the user information.{" "}
          <span className="font-section-normal-text-testimonials-medium color-black">
            Example:
          </span>{" "}
          User wants to reset their password and provides the new password to
          the chatbot, and the bot will update the password and will not store
          or display.
        </p>
        <img src={capabilities_10} alt="sensitive data handling"></img>
      </>
    ),
  },
  {
    header: "Dynamic Notifications",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">
            Dynamic Notifications
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18 ">
            Proactively notify users on chat to take action at workplace. Be it
            updating their passwords, filling surveys, updating OKRs, personal
            info, or simply surprise them on their birthdays/anniversaries, etc.
            Never let your employees miss a beat.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Bot notifications can be setup using app triggers that can initiate
            the bot to take an action. e.g password expiry, employee onboarding
            etc. Endless possibilities.
          </p>
        </div>
        <div className="support_content_right_container pb-0">
          <img src={capabilities_11} alt="Notifications"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <React.Fragment>
        {" "}
        <h3 className="font-section-normal-text-medium-customer-banner">
          Dynamic Notifications
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-1">
          Proactively notify users on chat to take action at workplace. Be it
          updating their passwords, filling surveys, updating OKRs, personal
          info, or simply surprise them on their birthdays/anniversaries, etc.
          Never let your employees miss a beat.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          Bot notifications can be setup using app triggers that can initiate
          the bot to take an action. e.g password expiry, employee onboarding
          etc. Endless possibilities.
        </p>
        <img src={capabilities_11} alt="Notifications"></img>
      </React.Fragment>
    ),
  },
  {
    header: "Analytics",
    hasGridLayoutLargeSmall: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">
            Analytics & logs - deep dive on chatbot interactions
          </h3>

          <p className="font-section-normal-text-testimonials line-height-18 ">
            Great dashboards are useless, said no-one ever. Monitor your chatbot
            performance easily from a single pane of glass.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Review chatbot usage, bot sessions, user queries, live status and
            much more.
          </p>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={capabilities_12} alt="Analytics"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <React.Fragment>
        {" "}
        <h3 className="font-section-normal-text-medium-customer-banner">
          Analytics & logs - deep dive on chatbot interactions
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18  pb-1">
          Great dashboards are useless, said no-one ever. Monitor your chatbot
          performance easily from a single pane of glass.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18  pb-0">
          Review chatbot usage, bot sessions, user queries, live status and much
          more.
        </p>
        <img src={capabilities_12} alt="Analytics"></img>
      </React.Fragment>
    ),
  },
  {
    header: "Chat History",
    hasGridLayoutLargeSmall: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        <div className="support_content_left_container it_support_left_content_container ">
          <h3 className="font-section-normal-text-medium-link">Chat History</h3>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            Using Chat History, you can quickly comb through conversations to
            possibly spot unsuccessful conversation and improve chatbot
            effectiveness. Analyze chatbot and user conversations, user
            experience, and end-user feedback.
          </p>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={capabilities_13} alt="chat history"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-normal-text-medium-link">Chat History</h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          Using Chat History, you can quickly comb through conversations to
          possibly spot unsuccessful conversation and improve chatbot
          effectiveness. Analyze chatbot and user conversations, user
          experience, and end-user feedback.
        </p>
        <img src={capabilities_13} alt="chat history"></img>
      </>
    ),
  },
];

const tabsForHrItImages = [
  capabilities_1,
  capabilities_2,
  capabilities_4,
  capabilities_5,
  capabilities_6,
  capabilities_7,
  capabilities_8,
  capabilities_9,
  capabilities_10,
  capabilities_11,
  capabilities_12,
  capabilities_13,
];

export function tabSliderInit_November(tabData) {
  return { activeIndex: 0, totalTabs: tabData.length };
}

export const tabSliderReducerWithLoop_November = (state, action) => {
  // console.log("DOING ::: reducer ", state, action)
  switch (action.type) {
    case "to": {
      // console.log("DOING ::: reducer :: 1", state, action, action.toTab < state.totalTabs, action.toTab >= 0)
      if (action.toTab < state.totalTabs && action.toTab >= 0) {
        const toReturn = { ...state, activeIndex: action.toTab };
        // console.log("DOING ::: reducer :: 2", toReturn)
        return toReturn;
      }
      // console.log("DOING ::: reducer :: 3");
      return state;
    }
    case "next":
      if (state.activeIndex == state.totalTabs - 1) {
        return { ...state, activeIndex: 0 };
      }

      if (state.activeIndex < state.totalTabs - 1) {
        return { ...state, activeIndex: state.activeIndex + 1 };
      }
      return state;
    case "prev":
      if (state.activeIndex == 0) {
        return { ...state, activeIndex: state.totalTabs - 1 };
      }
      if (state.activeIndex < state.totalTabs && state.activeIndex > 0) {
        return { ...state, activeIndex: state.activeIndex - 1 };
      }
      return state;
    case "reset":
      return { ...state, activeIndex: 0 };
    default:
      throw new Error("Invalid action");
  }
};
export function SectionWithImagesWithExternalClick({ slides, buttonMenu }) {
  const [currentTabState, dispatchTabAction] = React.useReducer(
    tabSliderReducerWithLoop_November,
    slides,
    tabSliderInit_November
  );

  // const accordElem = React.useRef(null);

  const imageToDisplay = slides[currentTabState.activeIndex].image;

  const altTag = slides[currentTabState.activeIndex].title;

  const allTitles = slides.map((slide) => slide.title);
  const allImages = slides.map((slide) => slide.image);
  usePreloadImages_November(allImages);

  // const allText = slides.map((slide) => slide.text);
  return (
    <>
      <div className="support_content_left_container it_support_left_content_container">
        {" "}
        <h3 className="font-section-normal-text-medium-link">
          IT Helpdesk Chatbot + IT Workflow Automation in a single platform
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18">
          Workativ platform provides one-click integration of IT chatbot with
          your apps to streamline and automate repetitive IT issues & requests
          such as &nbsp;
          <span
            className="external_slider_links"
            onClick={() => dispatchTabAction({ type: "to", toTab: 0 })}
          >
            unlock account
          </span>
          ,{" "}
          <span
            className="external_slider_links"
            onClick={() => dispatchTabAction({ type: "to", toTab: 1 })}
          >
            password resets
          </span>
          ,{" "}
          <span
            className="external_slider_links"
            onClick={() => dispatchTabAction({ type: "to", toTab: 2 })}
          >
            user provisioning
          </span>
          ,{" "}
          <span
            className="external_slider_links"
            onClick={() => dispatchTabAction({ type: "to", toTab: 3 })}
          >
            access management
          </span>
          ,{" "}
          <span
            className="external_slider_links"
            onClick={() => dispatchTabAction({ type: "to", toTab: 4 })}
          >
            asset requests
          </span>
          , and employee processes like{" "}
          <span
            className="external_slider_links"
            onClick={() => dispatchTabAction({ type: "to", toTab: 5 })}
          >
            {" "}
            employee onboarding
          </span>
          ,{" "}
          <span
            className="external_slider_links"
            onClick={() => dispatchTabAction({ type: "to", toTab: 6 })}
          >
            {" "}
            employee offboarding
          </span>{" "}
          and much more.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0 color-light-black">
          Expect a big drop in calls and emails to your IT Helpdesk.
        </p>
      </div>
      <div className="right_container_tab_slider_it_suuport">
        <div className="left_cards_tab_appworkflow">
          <h5 className="font-name-smaller-text text-align-center mb-0">
            Click below to view<br></br> different usecase
          </h5>

          <img src={workflow_arrow} className="workflow_arrow_svg"></img>
          <div className="cards_all_main_container_app_workflow">
            {allTitles.map((title, i) => (
              <div
                className={`cards_single_main_container_app_workflow cursor-pointer ${
                  i === currentTabState.activeIndex
                    ? "selected_tab_workflow"
                    : ""
                }`}
                onClick={() => dispatchTabAction({ type: "to", toTab: i })}
              >
                <h5 className="font-name-smaller-text mb-0">{title}</h5>{" "}
                <img src={arrow}></img>
              </div>
            ))}
          </div>
        </div>
        <div className="right_cards_tab_appworkflow">
          <img classname="w-100" src={imageToDisplay} alt={altTag}></img>
        </div>
      </div>
    </>
  );
}
export function SectionWithImagesWithExternalClickMobile({ slides }) {
  const [currentTabState, dispatchTabAction] = React.useReducer(
    tabSliderReducerWithLoop_November,
    slides,
    tabSliderInit_November
  );

  // const accordElem = React.useRef(null);

  const imageToDisplay = slides[currentTabState.activeIndex].image;

  const textToDisplay = slides[currentTabState.activeIndex].title;

  const allTitles = slides.map((slide) => slide.title);

  const allImages = slides.map((slide) => slide.image);
  usePreloadImages_November(allImages);

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      incrementOffset();
    },
    onSwipedRight: () => {
      decrementOffset();
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  function incrementOffset() {
    dispatchTabAction({ type: "next" });
  }

  function decrementOffset() {
    dispatchTabAction({ type: "prev" });
  }
  return (
    <>
      <h3 className="font-section-normal-text-medium-link">
        IT Helpdesk Chatbot + IT Workflow Automation in a single platform
      </h3>
      <p className="font-section-normal-text-testimonials line-height-18 pb-1">
        Workativ platform provides one-click integration of IT chatbot with your
        apps to streamline and automate repetitive IT issues & requests such
        as&nbsp;
        <span
          className="external_slider_links"
          onClick={() => dispatchTabAction({ type: "to", toTab: 0 })}
        >
          unlock account
        </span>
        ,{" "}
        <span
          className="external_slider_links"
          onClick={() => dispatchTabAction({ type: "to", toTab: 1 })}
        >
          password resets
        </span>
        ,{" "}
        <span
          className="external_slider_links"
          onClick={() => dispatchTabAction({ type: "to", toTab: 2 })}
        >
          user provisioning
        </span>
        ,{" "}
        <span
          className="external_slider_links"
          onClick={() => dispatchTabAction({ type: "to", toTab: 3 })}
        >
          access management
        </span>
        ,{" "}
        <span
          className="external_slider_links"
          onClick={() => dispatchTabAction({ type: "to", toTab: 4 })}
        >
          asset requests
        </span>
        , and employee processes like{" "}
        <span
          className="external_slider_links"
          onClick={() => dispatchTabAction({ type: "to", toTab: 5 })}
        >
          {" "}
          employee onboarding
        </span>
        ,{" "}
        <span
          className="external_slider_links"
          onClick={() => dispatchTabAction({ type: "to", toTab: 6 })}
        >
          {" "}
          employee offboarding
        </span>{" "}
        and much more.
      </p>
      <p className="font-section-normal-text-testimonials line-height-18 pb-0 color-light-black">
        Expect a big drop in calls and emails to your IT Helpdesk.
      </p>

      <div className="right_container_tab_slider_it_suuport" {...handlers}>
        <img className="w-75 m-auto" src={imageToDisplay}></img>

        <div className="it_app_workflow_mobile_tab_slider">
          <img
            src={arrow_round_mobile}
            className="support_tab_left_arrow_mobile"
            onClick={() => dispatchTabAction({ type: "prev" })}
          ></img>
          <h5 className="font-section-normal-text-medium  mb-0">
            {textToDisplay}
          </h5>
          <img
            src={arrow_round_mobile}
            className="support_tab_right_arrow_mobile"
            onClick={() => dispatchTabAction({ type: "next" })}
          ></img>
        </div>
      </div>
    </>
  );
}
export function TestiMonialsUseCases() {
  const isSmall = useMedia({ maxWidth: "520px" });
  return isSmall ? (
    <section class="testimonial_background_main_container_mobile float-left">
      <div class="container">
        <div class="row">
          <div className="testimonial_feedback_box_mobile mb-5">
            <div className="testimonial_feedback_top_content_box_mobile">
              <p className="font-section-normal-text">
                <span>
                  <img
                    loading="lazy"
                    src={quotes}
                    className="quotes_image_testimonial"
                  ></img>
                </span>
                The setup and building of the Workativ Chatbot I felt was
                extremely well supported! My questions were answered quickly and
                at no point did I feel unsupported. I had multiple Zoom meetings
                through the process where we would meet to go through the build.
              </p>
              <p className="font-section-normal-text">
                I felt listened through what we absolutely had to have for this
                tool to be rolled out in our environment. Throughout the process
                we kept looking back at the business requirements to make sure
                we were aligning properly.
              </p>
            </div>
            <div className="testimonial_review_bottom_image_box_mobile">
              <div className="bottom_image_box_left">
                <img
                  loading="lazy"
                  src={reviewer_img_mobile}
                  className="testimonial_reviewer_image"
                ></img>
              </div>
              <div className="bottom_designation_box_right_mobile">
                <h5
                  className="font-section-sub-header"
                  style={{ color: "#526CB1" }}
                >
                  Dan Bottiger
                </h5>
                <h5 className="font-section-normal-text-medium reviewer_designation_hammer">
                  Director of Information Systems
                </h5>
                <img
                  loading="lazy"
                  src={hammer_logo}
                  className="testimonial_hammer_logo_mobile"
                ></img>
              </div>
            </div>
          </div>
          <div className="rating_box_main_container">
            <div className="rating_left_box">
              <div className="rating_star_box">
                <img
                  loading="lazy"
                  src={star_image}
                  className="rating_star_image"
                ></img>
                <img
                  loading="lazy"
                  src={star_image}
                  className="rating_star_image"
                ></img>
                <img
                  loading="lazy"
                  src={star_image}
                  className="rating_star_image"
                ></img>
                <img
                  loading="lazy"
                  src={star_image}
                  className="rating_star_image"
                ></img>
                <img
                  loading="lazy"
                  src={star_image}
                  className="rating_star_image"
                ></img>
                <span>5/5</span>
              </div>
              <p className="font-section-normal-text rating_paragraph_content line-height-2">
                <span>
                  <img
                    loading="lazy"
                    src={quotes}
                    className="quotes_image_testimonial"
                  ></img>
                </span>
                It just seems to work out of the box. If you have a decent
                outline of the needs the bot needs to fill it is really
                straightforward to set up and test. Truly self service.
              </p>

              <img
                loading="lazy"
                className="capterra_logo_left"
                src={capterra_logo}
              />
            </div>
            <div className="rating_right_box">
              <div className="rating_star_box">
                <img
                  loading="lazy"
                  src={star_image}
                  className="rating_star_image"
                ></img>
                <img
                  loading="lazy"
                  src={star_image}
                  className="rating_star_image"
                ></img>
                <img
                  loading="lazy"
                  src={star_image}
                  className="rating_star_image"
                ></img>
                <img
                  loading="lazy"
                  src={star_image}
                  className="rating_star_image"
                ></img>
                <img
                  loading="lazy"
                  src={star_image}
                  className="rating_star_image"
                ></img>
                <span>5/5</span>
              </div>
              <p className="font-section-normal-text rating_paragraph_content line-height-2">
                <span>
                  <img
                    loading="lazy"
                    src={quotes}
                    className="quotes_image_testimonial"
                  ></img>
                </span>
                The deployment and support team was exceptional! They worked
                hard to make sure our rollout was successful. When I had
                concerns they worked real hard to make sure they were addressed
                and resolved.
              </p>
              <img loading="lazy" src={g2_logo} className="g2_logo_right"></img>
            </div>
          </div>
        </div>
      </div>
    </section>
  ) : (
    <section class="testimonial_background_main_container float-left">
      <div className="container">
        <div className="row">
          <div className="main_container_testimonials_usecase">
            <div className="top_image_container_testimonials_usecase">
              <img
                loading="lazy"
                className="capterra_logo_left capterra_usecase"
                src={capterra_logo}
              />
              <img
                loading="lazy"
                src={star_image}
                className="rating_star_image"
              ></img>
              <img
                loading="lazy"
                src={star_image}
                className="rating_star_image"
              ></img>
              <img
                loading="lazy"
                src={star_image}
                className="rating_star_image"
              ></img>
              <img
                loading="lazy"
                src={star_image}
                className="rating_star_image"
              ></img>
              <img
                loading="lazy"
                src={star_image}
                className="rating_star_image"
              ></img>
              <span>5/5</span>
            </div>
            <div className="middle_content_container_testimonials_usecase">
              <h5 className="font-section-sub-header">
                “Workativ delivers and the implementation team is top notch.
                Very supportive and responsive to question”
              </h5>
              <p className="font-section-normal-text ">
                <span className="font-section-normal-text-medium">
                  Overall:&nbsp;
                </span>
                Very positive experience. We are able to grow the bot based on
                our internal needs. The cost and support can’t be beat for all
                the features the tool provides
              </p>
              <p className="font-section-normal-text ">
                <span className="font-section-normal-text-medium">
                  Pros:&nbsp;
                </span>
                It just seems to work out of the box. If you have a decent
                outline of the needs the bot needs to fill it is really
                straightforward to set up and test. Their deployment team offers
                insight on where to focus time and provides resources to go to
                quickly resolve questions on your own. Truly self service.
              </p>
              <p class="font-section-normal-text ">
                <span className="font-section-normal-text-medium">
                  Cons:&nbsp;
                </span>
                No complaints right now - when we have had issues they respond
                quickly with a plan and their follow through is excellent
              </p>
              <p class="font-section-normal-text mb-0">
                <span className="font-section-normal-text-medium">
                  Reasons for Choosing Workativ Assistant:&nbsp;
                </span>
                Cost and the willingness of Workativ to answer questions and
                ensure our internal business requirements were being met.
              </p>
            </div>
            <div className="bottom_reviewer_image_container_testimonials_usecase">
              <img
                loading="lazy"
                src={reviewer_image}
                className="testimonial_reviewer_image_landing_page"
              ></img>
              <div className="end_name_usecase">
                <h5
                  className="font-section-sub-header"
                  style={{ color: "#526CB1" }}
                >
                  Dan Bottiger
                </h5>
                <h5 className="font-section-normal-text-medium reviewer_designation_hammer">
                  Director of Information Systems, Hammer
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export function TestiMonialsUseCasesGoTo() {
  const isSmall = useMedia({ maxWidth: "520px" });
  return isSmall ? (
    <section class="testimonial_background_main_container_mobile float-left">
      <div class="container">
        <div class="row">
          <div className="testimonial_feedback_box_mobile mb-5">
            <div className="testimonial_feedback_top_content_box_mobile">
              <p className="font-section-normal-text">
                <span>
                  <img src={quotes} className="quotes_image_testimonial"></img>
                </span>
                After researching 5 different chatbot solutions, we decided to
                go ahead with Workativ, for its’ easy integration with systems
                that we use internally at GoTo. Workativ creatively stood out
                with its clever mix of advanced chatbot, app workflows, and deep
                MS Teams integration, a powerful platform purpose fit for
                employee support automation.
              </p>
              <p className="font-section-normal-text">
                All the feedback that we passed to the team, was directly turned
                into new functionalities in a matter of days! And we felt
                supported throughout the entire onboarding process.
              </p>
              <p className="font-section-normal-text">
                The number of automation possibilities is immense! We can
                empower our employees to self-service on tasks that were
                currently handled by IT techs.
              </p>
            </div>
            <div className="testimonial_review_bottom_image_box_mobile">
              <div className="bottom_image_box_left">
                <img
                  loading="lazy"
                  src={goto_manager_mobile}
                  className="testimonial_reviewer_image"
                ></img>
              </div>
              <div className="bottom_designation_box_right_mobile">
                <h5
                  className="font-section-sub-header"
                  style={{ color: "#526CB1" }}
                >
                  Gabriel Grecco
                </h5>
                <h5 className="font-section-normal-text-medium reviewer_designation_hammer">
                  Global Manager - IT Service Desk
                </h5>
                <img
                  src={goto_logo}
                  className="testimonial_hammer_logo_mobile"
                ></img>
              </div>
            </div>
          </div>
          <div className="rating_box_main_container">
            <div className="rating_left_box">
              <div className="rating_star_box">
                <img src={star_image} className="rating_star_image"></img>
                <img src={star_image} className="rating_star_image"></img>
                <img src={star_image} className="rating_star_image"></img>
                <img src={star_image} className="rating_star_image"></img>
                <img src={star_image} className="rating_star_image"></img>
                <span>5/5</span>
              </div>
              <p className="font-section-normal-text rating_paragraph_content line-height-2">
                <span>
                  <img src={quotes} className="quotes_image_testimonial"></img>
                </span>
                It just seems to work out of the box. If you have a decent
                outline of the needs the bot needs to fill it is really
                straightforward to set up and test. Truly self service.
              </p>

              <img className="capterra_logo_left" src={capterra_logo} />
            </div>
            <div className="rating_right_box">
              <div className="rating_star_box">
                <img src={star_image} className="rating_star_image"></img>
                <img src={star_image} className="rating_star_image"></img>
                <img src={star_image} className="rating_star_image"></img>
                <img src={star_image} className="rating_star_image"></img>
                <img src={star_image} className="rating_star_image"></img>
                <span>5/5</span>
              </div>
              <p className="font-section-normal-text rating_paragraph_content line-height-2">
                <span>
                  <img src={quotes} className="quotes_image_testimonial"></img>
                </span>
                The deployment and support team was exceptional! They worked
                hard to make sure our rollout was successful. When I had
                concerns they worked real hard to make sure they were addressed
                and resolved.
              </p>
              <img src={g2_logo} className="g2_logo_right"></img>
            </div>
          </div>
        </div>
      </div>
    </section>
  ) : (
    <section class="testimonial_background_main_container float-left">
      <div className="container">
        <div className="row">
          <div className="main_container_testimonials_usecase">
            <div className="top_image_container_testimonials_usecase">
              <img
                className="capterra_logo_left capterra_usecase"
                src={goto_logo}
              />
            </div>
            <div className="middle_content_container_testimonials_usecase">
              <p className="font-section-normal-text">
                <span>
                  <img src={quotes} className="quotes_image_testimonial"></img>
                </span>
                After researching 5 different chatbot solutions, we decided to
                go ahead with Workativ, for its’ easy integration with systems
                that we use internally at GoTo. Workativ creatively stood out
                with its clever mix of advanced chatbot, app workflows, and deep
                MS Teams integration, a powerful platform purpose fit for
                employee support automation.
              </p>
              <p className="font-section-normal-text">
                All the feedback that we passed to the team, was directly turned
                into new functionalities in a matter of days! And we felt
                supported throughout the entire onboarding process.
              </p>
              <p className="font-section-normal-text">
                The number of automation possibilities is immense! We can
                empower our employees to self-service on tasks that were
                currently handled by IT techs.
              </p>
            </div>
            <div className="bottom_reviewer_image_container_testimonials_usecase">
              <img
                src={goto_manager}
                loading="lazy"
                className="testimonial_reviewer_image_landing_page"
              ></img>
              <div className="end_name_usecase">
                <h5
                  className="font-section-sub-header"
                  style={{ color: "#526CB1" }}
                >
                  Gabriel Grecco
                </h5>
                <h5 className="font-section-normal-text-medium reviewer_designation_hammer">
                  Global Manager - IT Service Desk
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
